import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../assests/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const linkStyles = ({ isActive }) =>
    isActive
      ? "text-[#AED136] font-semibold border-none"
      : "text-black font-semibold hover:text-[#36454F] hover:border-b-2 hover:border-[#36454F] transition-colors transition-border duration-300 ease-in-out";

  const linkStylesNoHover = ({ isActive }) =>
    isActive ? "text-[#AED136] font-semibold" : "text-black font-semibold";
  const changeDisplay = () => {
    if (window.scrollY >= 60) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };

  window.addEventListener("scroll", changeDisplay);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="bg-bgColor w-full top-0 fixed left-0 z-10">
      <div
        className={`${display ? "hidden" : "block"} px-2 pt-[18px] pb-[6px]`}
      >
        <ul className="flex flex-wrap justify-center items-center md:space-x-6 space-x-2 font-semibold lg:text-[18px] md:text-[15px] text-[12px]">
          <li className="flex items-center">
            <span>
              <FaPhone />
            </span>{" "}
            +2348184593446
          </li>
          <li className="flex items-center">
            <span>
              <FaPhone />
            </span>{" "}
            +2349035233127
          </li>
          <li>Email: Office@altlumlinkconsults.com</li>
        </ul>
      </div>
      <div className="flex items-center justify-between px-6 md:px-20 py-2">
        <Link to="/">
          <div>
            <img src={logo} alt="logo" className="max-w-[105px]" />
          </div>
        </Link>
        <div className="hidden md:flex space-x-6 items-center lg:text-[16px] md:text-[15px] text-[12px] font-semibold text-black">
          <NavLink
            to="/"
            className={({ isActive }) => linkStyles({ isActive })}
            onClick={handleClose}
          >
            Home
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) => linkStyles({ isActive })}
            onClick={handleClose}
          >
            About
          </NavLink>
          <NavLink
            to="/projects"
            className={({ isActive }) => linkStyles({ isActive })}
            onClick={handleClose}
          >
            Projects
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) => linkStyles({ isActive })}
            onClick={handleClose}
          >
            Contact
          </NavLink>
        </div>

        <button className="block md:hidden text-2xl" onClick={handleClose}>
          {open ? "" : <GiHamburgerMenu />}
        </button>
      </div>

      {open ? (
        <>
          <div
            className="fixed inset-0 z-20 bg-black bg-opacity-50 block md:hidden"
            onClick={handleClose}
          ></div>
          <div className="fixed w-3/5 left-0 inset-0 z-30 p-6 bg-primary font-semibold  text-white block md:hidden">
            <div
              className="flex justify-end text-2xl cursor-pointer"
              onClick={handleClose}
            >
              <IoClose />
            </div>
            <div className="flex flex-col space-y-4 text-[20px]">
              <NavLink
                to="/"
                className={({ isActive }) => linkStylesNoHover({ isActive })}
                onClick={handleClose}
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className={({ isActive }) => linkStylesNoHover({ isActive })}
                onClick={handleClose}
              >
                About
              </NavLink>
              <NavLink
                to="/projects"
                className={({ isActive }) => linkStylesNoHover({ isActive })}
                onClick={handleClose}
              >
                Projects
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) => linkStylesNoHover({ isActive })}
                onClick={handleClose}
              >
                Contact
              </NavLink>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </nav>
  );
};

export default NavBar;
