import React from "react";

const Hero = ({ title, subTitle, description, hero }) => {
  return (
    <div className="  w-[85%] md:w-[60%] text-center relative font-bold">
      <h2 className=" md:text-[50px] text-[30px]">{title}</h2>
      <h4 className=" text-[20px] md:text-[24px]">{subTitle}</h4>
      <p className=" text-[15px] md:text-[24px] mt-[24px]">{description}</p>
      <p className="text-[24px] md:text-[32px] lg:text-[40px]">{hero}</p>
    </div>
  );
};

export default Hero;
