import React, { useEffect } from "react";
import NavBar from "../components/navbar";
import Hero from "../components/hero";
import HeroGrid from "../components/heroGrid";
import LogoCarousel from "../components/logoCarousel";
import Footer from "../components/footer";
import service1 from "../assests/Rectangle 7.png";
import service2 from "../assests/Rectangle 8.png";
import teamImage from "../assests/teamImage.png";
import bgImage from "../assests/aBg.png";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div>
        <NavBar />
        <div className="hidden md:grid grid-cols-2 lg:gap-[25px] md:gap-4 mt-[138px] bg-white">
          <div className="justify-self-center self-center ml-[32px]">
            <h2 className="md:text-[28px] lg:text-[40px] font-bold ">
              About A.L.L Consults
            </h2>
            <p className="md:text-[16px] text-[15px] md:mt-[24px] mt-[16px] font-bold">
              <strong>Alternate Luminary Link Consults (ALL CONSULTS)</strong>{" "}
              is a registered Nigerian consulting engineering firm. We offer a
              diverse range of services including structural design and
              supervision, civil and infrastructural engineering designs and
              coordination, value engineering and design reviews, project
              management, project supervision, construction, and QA/QC checks
              such as non-destructive testing on structures.
            </p>
          </div>

          <div>
            <img
              src={bgImage}
              className="lg:w-[671px] lg:h-[416px] md:w-full md:h-[360px]"
              alt=""
            />
          </div>
        </div>
        <div className="md:hidden block">
          <div className="bg-aboutHero bg-cover h-[50vh] mt-[138px] bg-no-repeat bg-center flex flex-col justify-center items-center text-white">
            <Hero hero="ABOUT A.L.L CONSULTS" />
          </div>
          <p className="text-[15px]  mt-[16px] font-bold w-[90%] mx-auto">
            <strong>Alternate Luminary Link Consults (A.L.L CONSULTS)</strong>{" "}
            is a registered Nigerian consulting engineering firm. We offer a
            diverse range of services including structural design and
            supervision, civil and infrastructural engineering designs and
            coordination, value engineering and design reviews, project
            management, project supervision, construction, and QA/QC checks such
            as non-destructive testing on structures.
          </p>
        </div>
        <div className="w-[85%] mx-auto text-center my-12">
          <h2 className="font-bold text-[20px] md:text-[24px] lg:text-[32px] border-b-2 border-primary inline-block">
            Our Goals
          </h2>
          <p className="text-[16px] my-6 font-normal">
            At Alternate Luminary Link Consults, our goal is to provide
            innovative, efficient, and high-quality engineering solutions. Our
            expert team is dedicated to advancing progress and ensuring success
            in every project we handle.
          </p>
        </div>
        <HeroGrid />
        <div className="w-[90%] mx-auto hidden md:grid grid-cols-3 gap-5">
          <div>
            <img src={service2} alt="" className="h-[349px]" />
          </div>
          <div>
            <ul className="text-[16px] list-disc font-semibold  pl-4 space-y-2">
              <li>Structural Design and Supervision</li>
              <li>
                Civil and Infrastructural Engineering Designs and Coordination
              </li>
              <li>Value Engineering and Design Reviews</li>
              <li>Project Management</li>
              <li>Project Management</li>
              <li>Project Supervision</li>
              <li>Construction</li>
              <li>QA/QC Checks (such as NDTs on structures)</li>
              <li>Renovations and Remodeling</li>
            </ul>
          </div>
          <div>
            <img src={service1} alt="" className="h-[368px]" />
          </div>
        </div>
        <div className="text-[#E1F5FE] bg-[#36454F] pt-6 pb-24 md:hidden block">
          <h2 className="text-[20px] md:text-[24px] lg:text-[32px] font-bold text-center">
            Services
          </h2>
          <div className="grid grid-cols-1 gap-16 w-[90%] mx-auto my-8">
            <div className="order-2">
              <ul className="text-[16px] list-disc font-semibold space-y-3 pl-4">
                <li>Structural Design and Supervision</li>
                <li>
                  Civil and Infrastructural Engineering Designs and Coordination
                </li>
                <li>Value Engineering and Design Reviews</li>
                <li>Project Management</li>
                <li>Project Management</li>
                <li>Project Supervision</li>
                <li>Construction</li>
                <li>QA/QC Checks (such as NDTs on structures)</li>
                <li>Renovations and Remodeling</li>
              </ul>
            </div>
            <div className="order-1">
              <img src={service1} alt="" className="md:h-[356px] h-[257px]" />
            </div>
            <div className="order-3">
              <img src={service2} alt="" className="md:h-[301px] h-[257px]" />
            </div>
            <div className="space-y-2 order-4">
              <p>
                Alternate Luminary Link Consults is a collaboration of
                experienced and well qualified engineers and technicians who
                have expertise in both traditional and high-tech engineering.
                Through this collaboration, A.L.L Consults provides a personal
                and unbiased service to every individual client and deals with
                every project, large or small, with a high degree of engineering
                quality and efficiency.
              </p>
              <p>
                Each of our key personnel has worked in various design firms
                across the entire landscape of the construction industry. By
                combining civil, structural, environmental engineering and
                management skills into selected project teams, we are able to
                provide a full range of services for complex projects.
              </p>
            </div>
          </div>
        </div>
        <div className="my-10 mx-auto w-[90%]">
          <div className="text-center">
            <h2 className=" text-[20px] md:text-[24px] lg:text-[32px] font-bold mb-[35px] border-b-2 border-primary inline-block">
              Our Method
            </h2>
          </div>
          <div>
            <ul className="text-[16px] list-disc pl-4 space-y-[22px] font-semibold">
              <li>
                Flexibility and adaptation to the needs of the clients; we set
                up tailor-made teams for each project
              </li>
              <li>Task forces including experts and engineers</li>
              <li>
                Association with other companies when required by the client or
                justified by other demands
              </li>
              <li>Constant research for cost effective solutions</li>
              <li>High level of quality assurance</li>
            </ul>
          </div>
        </div>
        <div className="my-10 mx-auto w-[90%]">
          <div className="text-center">
            <h2 className="lg:text-[32px] md:text-[24px] text-[18px] font-bold mb-[35px] border-b-2 border-primary inline-block">
              Our Team
            </h2>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 md:gap-[21px] gap-4">
            <div className="space-y-2 md:order-1 order-2">
              <p>
                Alternate Luminary Link Consults is a collaboration of
                experienced and well qualified engineers and technicians who
                have expertise in both traditional and high-tech engineering.
                Through this collaboration, A.L.L Consults provides a personal
                and unbiased service to every individual client and deals with
                every project, large or small, with a high degree of engineering
                quality and efficiency.
              </p>
              <p>
                Each of our key personnel has worked in various design firms
                across the entire landscape of the construction industry. By
                combining civil, structural, environmental engineering and
                management skills into selected project teams, we are able to
                provide a full range of services for complex projects.
              </p>
            </div>
            <div className="order-1 md:order-2 justify-self-center self-center">
              <img src={teamImage} alt="" className=" h-[235px] lg:h-[305px]" />
            </div>
          </div>
        </div>
        <LogoCarousel />
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
