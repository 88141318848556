import React, { useEffect } from "react";
import NavBar from "../components/navbar";
import Hero from "../components/hero";
import HeroGrid from "../components/heroGrid";
import LogoCarousel from "../components/logoCarousel";
import service1 from "../assests/Rectangle 7.png";
import service2 from "../assests/Rectangle 8.png";
import Projects from "../components/projects";
import Contact from "../components/contact";
import Footer from "../components/footer";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavBar />
      <div className="bg-hero bg-cover h-[60vh] mt-[138px] bg-no-repeat bg-center flex flex-col justify-center items-center text-white">
        <Hero
          title={
            <h1>
              <span className="text-[#AED136]">A.L.L </span>
              <span>Consults</span>
            </h1>
          }
          subTitle="(Alternate Luminary Link Consults)"
          description="Comprehensive Services in Structural Design, Infrastructure Engineering, Value Engineering, Project Management, Construction, and Quality Assurance"
        />
      </div>
      <HeroGrid />
      <LogoCarousel />
      <div className="text-center md:w-[80%] w-[90%] mx-auto">
        <h2 className="text-[20px] md:text-[24px] lg:text-[32px] font-bold ">
          About <span className="text-[#AED136] font-bold">A.L.L</span> Consults
        </h2>
        <p className="md:text-[16px] text-[15px] md:mt-[24px] mt-[16px] md:mb-[48px] mb-[32px] font-bold">
          <strong>Alternate Luminary Link Consults (A.L.L CONSULTS)</strong> is
          a registered Nigerian consulting engineering firm. We offer a diverse
          range of services including structural design and supervision, civil
          and infrastructural engineering designs and coordination, value
          engineering and design reviews, project management, project
          supervision, construction, and QA/QC checks such as non-destructive
          testing on structures.
        </p>
        <h2 className="font-bold text-[20px] md:text-[24px] lg:text-[32px] border-b-2 border-primary inline-block">
          Our Goals
        </h2>
        <p className="md:text-[16px] text-[15px] my-6 font-normal">
          At Alternate Luminary Link Consults, our goal is to provide
          innovative, efficient, and high-quality engineering solutions. Our
          expert team is dedicated to advancing progress and ensuring success in
          every project we handle.
        </p>
      </div>
      <div className="text-[#E1F5FE] bg-[#36454F] pt-6 pb-24">
        <h2 className="md:text-[32px] text-[18px] font-bold text-center">
          Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[34px] gap-16 w-[90%] md:w-[80%] mx-auto my-8">
          <div className="order-2 md:order-1">
            <ul className="text-[16px] list-disc font-[500px] space-y-3 pl-4">
              <li>Structural Design and Supervision</li>
              <li>
                Civil and Infrastructural Engineering Designs and Coordination
              </li>
              <li>Value Engineering and Design Reviews</li>
              <li>Project Management</li>
              <li>Project Management</li>
              <li>Project Supervision</li>
              <li>Construction</li>
              <li>QA/QC Checks (such as NDTs on structures)</li>
              <li>Renovations and Remodeling</li>
            </ul>
          </div>
          <div className="order-1 md:order-2">
            <img src={service1} alt="" className="md:h-[356px] h-[257px]" />
          </div>
          <div className="order-3 md:order-3">
            <img src={service2} alt="" className="md:h-[301px] h-[257px]" />
          </div>
          <div className="space-y-2 order-4 md:order-4">
            <p>
              Alternate Luminary Link Consults is a collaboration of experienced
              and well qualified engineers and technicians who have expertise in
              both traditional and high-tech engineering. Through this
              collaboration, A.L.L Consults provides a personal and unbiased
              service to every individual client and deals with every project,
              large or small, with a high degree of engineering quality and
              efficiency.
            </p>
            <p>
              Each of our key personnel has worked in various design firms
              across the entire landscape of the construction industry. By
              combining civil, structural, environmental engineering and
              management skills into selected project teams, we are able to
              provide a full range of services for complex projects.
            </p>
          </div>
        </div>
      </div>
      <Projects />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
