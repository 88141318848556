import image1 from "./assests/pimage1.png";
import image1b from "./assests/pimage1b.png";
import image1c from "./assests/pimage1c.png";
import image1d from "./assests/pimage1d.png";
import image2 from "./assests/pimage2.png";
import image2b from "./assests/pimage2b.png";
import image2c from "./assests/pimage2c.png";
import image2d from "./assests/pimage2d.png";
import image2e from "./assests/pimage2e.png";
import image2f from "./assests/pimage2f.png";
import image3 from "./assests/pimage3.png";
import image3b from "./assests/pimage3b.png";
import image4 from "./assests/pimage4.png";
import image4b from "./assests/pimage4b.png";
import image4c from "./assests/pimage4c.png";
import image4d from "./assests/pimage4d.png";
import image5 from "./assests/pimage5.png";
import image5b from "./assests/pimage5b.png";
import image5c from "./assests/pimage5c.png";
import image6 from "./assests/pimage6.png";
import image6c from "./assests/pimage6c.png";
import image6e from "./assests/pimage6e.png";
import image6f from "./assests/pimage6f.png";
import image6h from "./assests/pimage6h.png";
import image7 from "./assests/pimage7.png";
import image7b from "./assests/pimage7b.png";
import image8 from "./assests/pimage8.png";
import image8b from "./assests/pimage8b.png";
import image9 from "./assests/pimage9.png";
import image9b from "./assests/pimage9b.png";
import image9c from "./assests/pimage9c.png";
import image9d from "./assests/pimage9d.png";
import image10 from "./assests/pimage10.png";
import image10b from "./assests/pimage10b.png";
import image10c from "./assests/pimage10c.png";
import image10d from "./assests/pimage10d.png";
import image11 from "./assests/pimage11.png";
import image11b from "./assests/pimage11b.png";
import image11c from "./assests/pimage11c.png";
import image11d from "./assests/pimage11d.png";
import image12 from "./assests/pimage12.png";
import image12b from "./assests/pimage12b.png";
import image12c from "./assests/pimage12c.png";
import image12d from "./assests/pimage12d.png";
import image13 from "./assests/pimage13.png";

export const projectsData = [
  {
    id: "1",
    img: [image1, image1b, image1c, image1d],
    pname: "Proposed Recreational Facility at Ibadan, Oyo State",
    client: "Yetunde B. Lasebikan",
    status: "Design ongoing",
  },
  {
    id: "2",
    img: [image2, image2b, image2c, image2d, image2e, image2f],
    pname: "Proposed Clarence Gate Court I and II (Eko Atlantic City, VI)",
    client: "Clarence Gate Investment Limited",
    services: "Structural design and supervision",
    status: "Construction ongoing",
  },
  {
    id: "3",
    img: [image3, image3b],
    pname: "St. Ives Women, Children, Family and Fertility Clinic, Akowonjo",
    client: "Proyecto Technologies Limited",
    services: "Structural design and supervision",
    status: "Commissioned and functional",
  },
  {
    id: "4",
    img: [image4, image4b, image4c, image4d],
    pname: "Grace Court, Mekuwen Road, Ikoyi",
    client: "Clarence Gate Investment Limited",
    services: "Structural design and supervision",
    status: "Structure Completed, finishing ongoing",
  },
  {
    id: "5",
    img: [image5, image5b, image5c],
    pname: "Proposed Residential Development at Alexander Road, Ikoyi",
    client: "DLM Capital Group",
    services: "Structural design and consultancy services",
    status: "Construction Ongoing",
  },
  {
    id: "6",
    img: [image6, image6c, image6e, image6f, image6h],
    pname: "Duport Energy Centre, Egbokor, Edo State",
    client: "Integrated Projects Limited",
    services:
      "Civil, structural and infrastructural design and consultancy services",
    status: "Construction Completed (2021)",
  },
  {
    id: "7",
    img: [image7, image7b],
    pname: "KFA Court (Adeyemi Lawson, Ikoyi)",
    client: "Clarence Gate Investment Limited",
    services:
      "Structural design and consultancy from concept till completion. We also supervised the construction",
    status: "Completed and fully functional (2022)",
  },
  {
    id: "8",
    img: [image8, image8b],
    pname: "MD’s Court (Adeyemi Lawson Street, Ikoyi)",
    client: "Design Protocol Limited",
    services: "Structural design, consultancy and supervision",
    status: "Completed and fully functional (2017)",
  },
  {
    id: "9",
    img: [image9, image9b, image9c, image9d],
    pname: "Aduke Court (Mekuwen Road, Ikoyi)",
    client: "Design Protocol Limited",
    services:
      "Structural design and consultancy from concept till completion. We also supervised the construction",
    status: "Completed and fully functional (2019)",
  },
  {
    id: "10",
    img: [image10, image10b, image10c, image10d],
    pname: "Malvin Court (Turnbull Road, Ikoyi)",
    client: "Design Protocol Limited",
    services:
      "Structural design and consultancy from concept till completion. We also supervised the construction",
    status: "Completed and fully functional (2018)",
  },
  {
    id: "11",
    img: [image11, image11b, image11c, image11d],
    pname: "LUTH Outpatient Hospital, Idi araba, Lagos",
    client: "Integrated Projects Limited, Kratos Impact Limited",
    services: "Structural design and consultancy services",
    status: "Construction Ongoing",
  },
  {
    id: "12",
    img: [image12, image12b, image12c, image12d],
    pname: "SPORTS PAVILION (Ilaro, Ogun State)",
    client: "HEG International Limited",
    services:
      "Structural design and consultancy services. Approval documentation.",
    status: "Structure completed (2021)",
  },
  {
    id: "13",
    img: [image13],
    pname: "NaNa’s Place, Banana Island, Ikoyi.",
    client: "Clarence Gate Investment Limited",
    services:
      "Peer review of existing structural design and value engineering services. Client representation and advisory services.",
    status: "Construction Ongoing",
  },
];
