import { projectsData } from "../data";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import ProjectsDetail from "./projectsDetail";

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const closePopup = () => {
    setSelectedProject(null);
  };

  return (
    <div className="md:w-[80%] w-[90%] mx-auto py-16">
      <div className="text-center">
        <h3 className=" font-bold text-[20px] md:text-[24px] lg:text-[32px] mb-8">
          Some of our projects
        </h3>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 my-4">
        {projectsData.map((project) => (
          <div
            key={project.id}
            className="cursor-pointer pt-[40px] p-[20px] h-[460px] bg-white text-black rounded-[12px]"
            onClick={() => handleProjectClick(project)}
          >
            <img
              src={project.img[0]}
              alt={project.pname}
              className="w-full h-[264px] object-cover rounded-tl-[12px] rounded-tr-[12px] transform hover:scale-110"
            />
            <div className="mt-4">
              <p className="font-semibold text-[18px]">
                Client: {project.client}
              </p>
              <h4 className="text-[16px] font-normal">{project.pname}</h4>
            </div>
          </div>
        ))}
      </div>
      {selectedProject && (
        <>
          <div
            className="fixed inset-0 z-40 bg-black bg-opacity-50"
            onClick={closePopup}
          ></div>
          <div className="fixed top-0 bottom-0 left-0 right-0 m-auto w-full md:w-[600px] h-[500px] lg:h-[600px] z-50 scrollbar-hide md:scrollbar-visible overflow-y-scroll bg-bgColor">
            <div className="w-full bg-[#36454F] py-3">
              <div className="md:w-[80%] w-[90%] mx-auto md:text-[32px] text-[24px] text-[#E1F5FE] text-semibold">
                Projects Details
              </div>
            </div>
            <div className=" md:w-[80%] w-[90%] mx-auto pb-10">
              <div
                onClick={closePopup}
                className="flex justify-end md:text-4xl text-2xl md:my-[32px] my-[24px] font-semibold cursor-pointer"
              >
                <IoClose />
              </div>
              <ProjectsDetail project={selectedProject} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Projects;
