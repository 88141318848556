import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const projectsDetail = ({ project }) => {
  const isMultipleImages = project.img.length > 1;

  const settings = {
    infinite: isMultipleImages, // Disable infinite loop for a single image
    slidesToShow: 1, // Show only one slide whether there's one or multiple images
    slidesToScroll: 1, // Always scroll one slide at a time
    autoplay: isMultipleImages, // Disable autoplay if there's only one image
    autoplaySpeed: isMultipleImages ? 2000 : 0, // Set speed only for multiple images
  };

  return (
    <div className="w-full">
      <Slider {...settings}>
        {project.img.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`${project.pname} ${index + 1}`}
              className="w-full lg:h-[465px] md:h-[317px] h-[190px] object-cover object-center"
            />
          </div>
        ))}
      </Slider>
      <div className="md:w-[368px] w-[90%] my-[32px] mx-auto">
        <h1 className="md:text-[32px] text-[24px] font-bold">
          Project Details
        </h1>
        <div className="mt-[24px] space-y-3">
          <div>
            <h3 className="text-[15px]">Project name</h3>
            <h3 className="font-semibold text-[16px]">{project.pname}</h3>
          </div>
          <div>
            <h3 className="text-[15px]">Client</h3>
            <p className="font-semibold text-[16px]">{project.client}</p>
          </div>
          <div>
            <h3 className="text-[15px]">Status</h3>
            <p className="font-semibold text-[16px]"> {project.status}</p>
          </div>
          {project.services && (
            <div>
              <h3 className="text-[15px]">Services</h3>
              <p className="font-semibold text-[16px]"> {project.services}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default projectsDetail;
