import React, { useEffect } from "react";
import NavBar from "../components/navbar";
import Hero from "../components/hero";
import Contact from "../components/contact";
import Footer from "../components/footer";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <NavBar />
      <div className="bg-cHero bg-cover h-[50vh] mt-[138px] bg-no-repeat bg-center flex flex-col justify-center items-center text-white">
        <Hero hero="REACH OUT TO US" />
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactPage;
