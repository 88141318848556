import { Link } from "react-router-dom";
import logo from "../assests/logo.svg";

const Footer = () => {
  return (
    <div className="bg-primary mt-10">
      <div className="w-[90%] mx-auto py-10 text-[#E1F5FE]">
        <div className="grid lg:grid-cols-4 md:grid-cols-3 md:gap-[64px] gap-[42px] grid-cols-2 ">
          <div className="md:w-[235px] w-full col-span-2 md:col-span-1">
            <img src={logo} alt="logo" className="max-w-[105px]" />
            <p className="text-[14px] mt-[48px]">
              Alternate Luminary Link Consults (A.L.L CONSULTS) is a registered
              Nigerian consulting engineering firm offering services in
              structural design and supervision, civil and infrastructural
              engineering, value engineering, project management, construction,
              and QA/QC checks including non-destructive testing.
            </p>
          </div>
          <div className="w-[71px] md:justify-self-center">
            <h4 className="text-[16px] font-semibold">Company</h4>
            <ul className="mt-[48px] text-[14px] space-y-[15px] flex flex-col">
              <Link to="/">Home</Link>
              <Link to="/about-us">About us</Link>
              <Link to="/projects">Projects</Link>
              <Link to="/contact">Contact</Link>
            </ul>
          </div>
          <div className="md:w-[235px] w-full">
            <h4 className="text-[16px] font-semibold">Services</h4>
            <ul className="mt-[48px] text-[14px] space-y-[15px]">
              <li>Construction</li>
              <li>QA/QC Checks (such as NDTs on structures)</li>
              <li>Renovations and Remodeling</li>
              <li>Structual Design and Supervision</li>
              <li>
                Civil and Infrastructural Engineering Designs and Coordination
              </li>
              <li>Value Engineering and Design Reviews</li>
              <li>Project Management</li>
              <li>Project Supervision</li>
            </ul>
          </div>
          <div className="md:w-[235px] w-[198px]">
            <h4 className="text-[16px] font-semibold">Contacts</h4>
            <ul className="mt-[48px] text-[14px] space-y-[15px]">
              <li>A.L.L Consults.</li>
              <li>Address: 2, AJE STREET, SABO, YABA, LAGOS STATE, NIGERIA.</li>
              <div>
                <li>Tel 1: +234 818 4593 446</li>
                <li>Tel 2: +234 903 5233 127</li>
              </div>
              <li>Email: Office@altlumlinkconsults.com</li>
            </ul>
          </div>
        </div>
        <p className="text-center my-[70px]">
          © A.L.L Consults. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
