import { BsPhone } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { TbMail } from "react-icons/tb";
import logo from "../assests/logo.svg";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const serviceId = "service_5e97zgr";

    const templateId = "template_7iz1w23";

    const publicKey = "-Ju-Ty2ltIU1Vrnqd";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Office@altlumlinkconsults.com",
      message: message,
    };

    try {
      const response = await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        publicKey
      );
      console.log("SUCCESS!", response.status, response.text);
      setName("");
      setEmail("");
      setMessage("");
      toast.success("Email sent successfully!");
    } catch (error) {
      console.log("FAILED...", error);
      toast.error("Failed to send email. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[#F3F3F3] w-full my-[16px]">
      <ToastContainer />
      <div className=" w-[90%] mx-auto py-[24px]">
        <h3 className="font-semibold text-[20px] md:text-[24px] lg:text-[32px] text-center md:mb-[68px] mb-[32px]">
          Need our services? Contact us
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-custom-md md:gap-4 gap-16">
          <div className="flex md:flex-row flex-col justify-center items-center space-x-4">
            <div className="md:block hidden ">
              <img src={logo} alt="logo" className="max-w-[105px]" />
            </div>
            <div className="flex flex-col space-y-4 font-semibold lg:border-l-2 lg:border-[#000000B2] lg:pl-[51px]">
              <div className="flex gap-2 items-center">
                <div className="md:text-[32px] text-[20px]">
                  <BsPhone />
                </div>
                <div>
                  <ul className="md:w-[321px] md:text-[18px] text-[15px]">
                    <li>Tel 1: +234 818 4593 446</li>
                    <li>Tel 2: +234 903 5233 127</li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="md:text-[32px] text-[20px]">
                  <TbMail />
                </div>
                <div>
                  <p className="md:w-[321px] md:text-[18px] text-[15px]">
                    Email: Office@altlumlinkconsults.com
                  </p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="md:text-[32px] text-[20px]">
                  <GrLocation />
                </div>
                <div>
                  <p className="md:w-[321px] md:text-[18px] text-[15px]">
                    Address: 2, AJE STREET, SABO, YABA, LAGOS STATE, NIGERIA.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-full w-[80%] mx-auto">
            <form className="space-y-[24px]" onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  className="p-3 bg-[#F3F3F3] rounded-md border-gray-300 border-[0.7px] w-full "
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="p-3 bg-[#F3F3F3] rounded-md border-[0.7px] border-gray-300 w-full "
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <textarea
                  name="message"
                  placeholder="Message"
                  className="p-3 rounded-md bg-[#F3F3F3] border-[0.7px] h-[194px] resize-none border-gray-300 w-full "
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <button
                className="w-full h-[42px] text-center bg-primary hover:bg-[#AED136] rounded-xl text-[#E1F5FE] text-[16px]"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
