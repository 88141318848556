import React, { useEffect } from "react";
import NavBar from "../components/navbar";
import Hero from "../components/hero";
import Projects from "../components/projects";
import Footer from "../components/footer";

const ProjectsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <NavBar />
      <div className="bg-pHero bg-cover h-[50vh] mt-[138px] bg-no-repeat bg-center flex flex-col justify-center items-center text-white">
        <Hero hero="SOME OF OUR PROJECTS" />
      </div>
      <Projects />
      <Footer />
    </div>
  );
};

export default ProjectsPage;
