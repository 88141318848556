import image5 from "../assests/Ellipse 26.svg";
import image6 from "../assests/Ellipse 27.svg";
import image7 from "../assests/Ellipse 28.svg";
import image8 from "../assests/Ellipse 29.svg";
import image9 from "../assests/Ellipse 40.svg";
import image2 from "../assests/Ellipse 24.svg";
import image3 from "../assests/Ellipse 25.svg";
import image1 from "../assests/Ellipse 48.svg";
import image4 from "../assests/Ellipse 57.svg";

const LogoCarousel = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];

  return (
    <div className="relative overflow-hidden py-3 my-6 mx-auto bg-transparent whitespace-nowrap w-[90%]">
      <div className="absolute top-0 left-0 w-64 h-full bg-gradient-to-l from-transparent to-transparent z-10"></div>
      <div className="absolute top-0 right-0 w-64 h-full bg-gradient-to-r from-transparent to-transparent z-10"></div>

      <div className="logos-slide inline-block animate-slide">
        {images.concat(images).map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`logo-${index}`}
            className="lg:h-40 h-24 mx-10 inline-block"
          />
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
