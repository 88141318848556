import Vector1 from "../assests/Vector1.png";
import Vector2 from "../assests/Vector2.png";
import Vector3 from "../assests/Vector3.png";
import Vector4 from "../assests/Vector4.png";

const HeroGrid = () => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4 my-10 md:w-[80%] w-[90%] mx-auto font-semibold">
      <div className="bg-[#EBEBEB] flex flex-col justify-center space-y-1 items-center p-[10px] h-[164px] border-[0.6px] border-[#656565] rounded-[16px] shadow-[0px_3px_3px_0px_#35353540]">
        <div>
          <img src={Vector1} alt="" />
        </div>
        <p className="text-center">Structural Design and Supervision</p>
      </div>
      <div className="bg-[#EBEBEB] flex flex-col justify-center space-y-1 items-center p-[10px] h-[164px] border-[0.6px] border-[#656565] rounded-[16px] shadow-[0px_3px_3px_0px_#35353540]">
        <div>
          <img src={Vector2} alt="" />
        </div>
        <p className="text-center">
          Civil & Infrastructual Engineering Designs and coordination
        </p>
      </div>
      <div className="bg-[#EBEBEB] flex flex-col justify-center space-y-1 items-center p-[10px] h-[164px] border-[0.6px] border-[#656565] rounded-[16px] shadow-[0px_3px_3px_0px_#35353540]">
        <div>
          <img src={Vector3} alt="" />
        </div>
        <p className="text-center">Construction</p>
      </div>
      <div className="bg-[#EBEBEB] flex flex-col justify-center space-y-1 items-center p-[10px] h-[164px] border-[0.6px] border-[#656565] rounded-[16px] shadow-[0px_3px_3px_0px_#35353540]">
        <div>
          <img src={Vector4} alt="" />
        </div>
        <p className="text-center">Value Engineering & Designs reviews</p>
      </div>
    </div>
  );
};

export default HeroGrid;
